export const statusOptions = [
  { value: "All", label: "All" },
  { value: "Success", label: "Success" },
  { value: "Failed", label: "Failed" },
  { value: "Pending", label: "Pending" },
  { value: "Processing", label: "Processing" },
  { value: "Initialized", label: "Initialized" },
];

export const statusOptionsWithdrawal = [
  { value: "All", label: "All" },
  { value: "Success", label: "Success" },
  { value: "Failed", label: "Failed" },
  { value: "Pending", label: "Pending" },
  // { value: "Processing", label: "Processing" },
  // { value: "Initialized", label: "Initialized" },
];

export const statusTypes = {
  Success: "Success",
  Pending: "Pending",
  Failed: "Failed",
  Processing: "Processing",
  Expired: "Expired",
  Initialized: "Initialized",
  All: "All",
  "Not Attempted": "Not Attempted",
  Default: "Default",
};
export const limitOptions = [
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 200, label: 200 },
  { value: 300, label: 300 },
  { value: 400, label: 400 },
  { value: 500, label: 500 },
];
