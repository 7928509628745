import { useEffect, useState, Fragment } from "react";

// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  Loader,
  Select,
  Table,
  TableRow,
} from "../../ui";

// PROJECT IMPORT
import { useReportData } from "./reportSlice";
import { useAddReportMutation, useGetReportMutation } from "./reportApi";
import { payinOptions } from "./mock";
import { FILTER } from "../../constant";
import { getStatusTypes } from "../../ui/theme";
import { dateFormatter, showToaster } from "../../helperFunctions";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { statusOptions } from "../common/mock";

const Report = () => {
  const reportData = useReportData();
  const [getReport, { isLoading }] = useGetReportMutation();
  const [addReport] = useAddReportMutation();
  const [filter, setFilter] = useState(FILTER);
  const [isLoader, setIsLoader] = useState(false);

  const schema = yup.object({
    payin: yup.string().required("Please Enter Report Type"),
    all: yup.string().required("Please Fill This Field"),
    // date: yup.mixed().required("Please Enter Date"),
    // successDate: yup.mixed().required("Please Enter Success Date"),
  });

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const columns = [
    { title: "Downloaded", name: "created_at" },
    { title: "Report Type", name: "reportType" },
    { title: "Date", name: "reportDate" },
    {
      title: "Status",
      Cell: (data: any) => {
        return (
          <span className={`${getStatusTypes(data?.reportSts)}`}>
            {data.reportSts}
          </span>
        );
      },
    },
    { title: "No.Of Record", name: "record" },
    { title: "Expire at", name: "expiryDate" },
    {
      title: "Action",
      Cell: (data: any) => {
        return (
          <>
            {data?.report_is_download === "Success" ? (
              <Button
                href={`${process.env.REACT_APP_BASE_URL}/report/download/${data?.reportId}`}
                className="!h-0 !py-4 mr-2 max-w-[100px]"
              >
                Download
              </Button>
            ) : null}
          </>
        );
      },
    },
  ];

  const onGetData = () => {
    const payload: any = { ...filter };
    delete payload["isFilter"];
    getReport(payload);
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async (values: any) => {
    try {
      setIsLoader(true);
      const payload = {
        report_type: values.payin,
        status: values.all,
        InitiateDate: dateFormatter(values?.date?.[0], "start"),
        FinalizeDate: dateFormatter(values?.date?.[1], "end"),
        successStartDate: dateFormatter(values?.successDate?.[0], "start"),
        successEndDate: dateFormatter(values?.successDate?.[1], "end"),
      };
      const res: any = await addReport(payload).unwrap();
      if (res?.status !== false) {
        onGetData();
        setIsLoader(false);
        reset();
      }
      showToaster(res?.message);
      setFilter({ ...FILTER });
    } catch (err: any) {
      setIsLoader(false);
    }
  };

  return (
    <>
      <form
        className="flex flex-wrap gap-2 items-start px-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Select
          name="payin"
          label="Report Type"
          options={payinOptions}
          register={register}
          errors={errors}
        />
        <Select
          name="all"
          label="All"
          options={statusOptions}
          register={register}
          errors={errors}
        />
        <ControlledDatePicker
          name="date"
          label="Select Date"
          placeholder="Date"
          options={{
            mode: "range",
          }}
          control={control}
          // errors={errors}
        />
        <ControlledDatePicker
          name="successDate"
          label="Success Date"
          placeholder="Select Date"
          options={{
            mode: "range",
          }}
          control={control}
          // errors={errors}
        />
        {/* <Button type="submit" className="mt-8">
          
        </Button> */}
        {!isLoader ? (
          <Button type="submit" className="mt-[22px] !h-9 !text-sm">
            Generate
          </Button>
        ) : (
          <div className="flex item-center justify-center mt-6">
            <Loader />
          </div>
        )}
      </form>

      <div className="App mt-3">
        <Table
          columns={columns}
          isLoading={isLoading}
          data={reportData?.data}
          count={reportData?.total_item}
          pagination={filter}
          isExpendable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
        >
          {reportData?.data?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow columns={columns} item={item} isExpendable={false} />
              </Fragment>
            );
          })}
        </Table>
      </div>
    </>
  );
};

export default Report;
